// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-views-404-html-jsx": () => import("./../../../src/views/404.html.jsx" /* webpackChunkName: "component---src-views-404-html-jsx" */),
  "component---src-views-about-html-jsx": () => import("./../../../src/views/about.html.jsx" /* webpackChunkName: "component---src-views-about-html-jsx" */),
  "component---src-views-cooperate-html-jsx": () => import("./../../../src/views/cooperate.html.jsx" /* webpackChunkName: "component---src-views-cooperate-html-jsx" */),
  "component---src-views-demos-html-jsx": () => import("./../../../src/views/demos.html.jsx" /* webpackChunkName: "component---src-views-demos-html-jsx" */),
  "component---src-views-download-html-jsx": () => import("./../../../src/views/download.html.jsx" /* webpackChunkName: "component---src-views-download-html-jsx" */),
  "component---src-views-example-detail-html-jsx": () => import("./../../../src/views/exampleDetail.html.jsx" /* webpackChunkName: "component---src-views-example-detail-html-jsx" */),
  "component---src-views-index-html-jsx": () => import("./../../../src/views/index.html.jsx" /* webpackChunkName: "component---src-views-index-html-jsx" */),
  "component---src-views-price-html-jsx": () => import("./../../../src/views/price.html.jsx" /* webpackChunkName: "component---src-views-price-html-jsx" */),
  "component---src-views-springinfo-html-jsx": () => import("./../../../src/views/springinfo.html.jsx" /* webpackChunkName: "component---src-views-springinfo-html-jsx" */)
}


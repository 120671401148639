require('sanitize.css')
require('sanitize.css/typography.css')
require('sanitize.css/forms.css')
require('./src/global.css')

export const onClientEntry = () => {
  function getCookie(name) {
    const parts = document.cookie.split(/;\s*/)
    for (let i = 0; i < parts.length; i++) {
      let partMap = parts[i].split('=')
      if (partMap[0] === name) {
        return partMap[1]
      }
    }
    return null
  }
  function setCookie(name, value, Days) {
    const OneDay = 24 * 60 * 60
    const maxAge = OneDay * Days
    document.cookie = `${name}=${value};max-age=${maxAge};expires=${new Date(
      Date.now() + maxAge * 1000,
    )};path=/;domain=.dayancloud.com`
  }

  const blockComefrom = getCookie('blockComefrom')
  if (!blockComefrom || Date.now() > +blockComefrom) {
    let referrer = document.referrer || document.URL
    if (document.referrer && window.location.search.includes('source=')) {
      if (referrer.includes('&')) {
        referrer = `${referrer}&${window.location.search.slice(1)}`
      } else {
        referrer += window.location.search
      }
    }
    // 记录24小时内不可覆盖
    setCookie('blockComefrom', new Date().setDate(new Date().getDate() + 1), 1)
    // 记录保持7天
    setCookie('comefrom', referrer, 7)
  }
}
